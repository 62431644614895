export enum ContentClass {
  Video = 'video',
  Audio = 'audio',
  Image = 'image',
}

export enum ContentType {
  Footage = 'footage',
  Template = 'template',
  Motion_bg = 'motion-background',
  Vr_360 = 'vr360',
  Illustration = 'illustration',
  Vector = 'vector',
  Photo = 'photo',
  Sound_effect = 'sfx',
  Music = 'music',
}

export enum SearchFilterContentTypes {
  All_videos_content_type = 'all-videos',
  All_audio_content_type = 'all-audio',
  All_images_content_type = 'all-images',
  Footage = 'footage',
  Motion_bgs = 'motion-backgrounds',
  Templates = 'templates',
  Music = 'music',
  Sound_effects = 'sfx',
  Photos = 'photos',
  Vectors = 'vectors',
  Illustrations = 'illustrations',
}
